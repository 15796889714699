import React, { useContext, useState } from 'react'
import {Appstate} from '../App' 
import GamepadIcon from '@mui/icons-material/Gamepad';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Groups2Icon from '@mui/icons-material/Groups2';
import WebhookIcon from '@mui/icons-material/Webhook';
import InfoIcon from '@mui/icons-material/Info';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Link, useLocation } from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LockIcon from '@mui/icons-material/Lock';

const Sidebar = () => {
  const useAppState = useContext(Appstate);
  const location = useLocation();

  return (
    <div>
      <div class={`sidebar ${useAppState.show ? 'sidebar-open' : 'md:sidebar-open sidebar-closed'} p-4 text-gray-400 text-sm flex overflow-y-auto flex-col items-center h-screen md:w-1/5 w-3/5 fixed top-0 left-0 transform transition-transform duration-300 ease-in-out opacity-95 md:opacity-100`}>
      <Link to={'/'}><h1 className='font-bold text-2xl font-mono text-white flex justify-center items-center'><img src='../rtr.png' className='h-12 mr-2' /> Rector <p className='text-xs text-lime-500 font-mono ml-2'>~ ${useAppState.price}</p></h1></Link>
        <ul>
          <Link to={'/dashboard'} onClick={() => useAppState.isMd ? useAppState.setShow(true) : useAppState.setShow(false)} ><li className={`${location.pathname == '/dashboard' ? "active_link text-white border-none" : ""} hover:border-green-500 p-2 flex flex-col justify-center rounded-lg items-center border border-gray-500 mt-4`}>
            <p><GamepadIcon color='inherit' fontSize='medium' /></p>
            <p>Dashboard</p>
          </li></Link>
          <Link to={'/deposit'} onClick={() => useAppState.isMd ? useAppState.setShow(true) : useAppState.setShow(false)} ><li className={`${location.pathname == '/deposit' ? "active_link text-white border-none" : ""} hover:border-green-500 p-2 flex flex-col justify-center rounded-lg items-center border border-gray-500 mt-4`}>
            <p><AccountBalanceIcon color='inherit' fontSize='medium' /></p>
            <p>Deposit</p>
          </li></Link>
          <Link to={'/teams'} onClick={() => useAppState.isMd ? useAppState.setShow(true) : useAppState.setShow(false)} ><li className={`${location.pathname == '/teams' ? "active_link text-white border-none" : ""} hover:border-green-500 p-2 flex flex-col justify-center rounded-lg items-center border border-gray-500 mt-4`}>
            <p><InfoIcon color='inherit' fontSize='medium' /></p>
            <p>Team Info</p>
          </li></Link>
          <Link to={'/teamusers'} onClick={() => useAppState.isMd ? useAppState.setShow(true) : useAppState.setShow(false)} ><li className={`${location.pathname == '/teamusers' ? "active_link text-white border-none" : ""} hover:border-green-500 p-2 flex flex-col justify-center rounded-lg items-center border border-gray-500 mt-4`}>
            <p><Groups2Icon color='inherit' fontSize='medium' /></p>
            <p>Teams</p>
          </li></Link>
          <Link to={'/wallet'} onClick={() => useAppState.isMd ? useAppState.setShow(true) : useAppState.setShow(false)} ><li className={`${location.pathname == '/wallet' ? "active_link text-white border-none" : ""} hover:border-green-500 p-2 flex flex-col justify-center rounded-lg items-center border border-gray-500 mt-4`}>
            <p><AccountBalanceWalletIcon color='inherit' fontSize='medium' /></p>
            <p>Wallet</p>
          </li></Link>
          {/* {useAppState.isAdmin ? */}
          {/* <Link to={'/manage'} onClick={() => useAppState.isMd ? useAppState.setShow(true) : useAppState.setShow(false)} ><li className={`${location.pathname == '/manage' ? "active_link text-white border-none" : ""} hover:border-green-500 p-2 flex flex-col justify-center rounded-lg items-center border border-gray-500 mt-4`}>
            <p><AdminPanelSettingsIcon color='inherit' fontSize='medium' /></p>
            <p>Manage</p>
          </li></Link> */}
          {/* : null} */}
        </ul>
      </div>  
    </div>
  )
}

export default Sidebar