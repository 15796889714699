import { useContext, useEffect, useState } from 'react';
import {Sidebar, Header} from './index';
import { Appstate } from '../App';
import { ethers } from 'ethers';
import { message } from 'antd';
import { TailSpin, ThreeDots } from 'react-loader-spinner';
import moment from 'moment';
import { SocialIcon } from 'react-social-icons';
import { Checkbox } from 'antd';
// import DepositHistory from './DepositHistory';
import { db } from './firebase';
import { getDoc, doc, setDoc, updateDoc, increment } from 'firebase/firestore';

const Deposit = () => {
  const useAppState = useContext(Appstate);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [bal, setBal] = useState(0);
  const [bal2, setBal2] = useState(0);
  const [bal3, setBal3] = useState(0);
  const [rtrAmt, setRtrAmt] = useState(0);
  const [oldRtrAmt, setOldRtrAmt] = useState(0);
  const [vUsdtAmt, setVUsdtAmt] = useState(0);
  const [usdAmt, setUsdAmt] = useState(0);
  const [useToken, setUseToken] = useState(false);
  const [useOldToken, setUseOldToken] = useState(false);
  const [useVUsdt, setuseVUsdt] = useState(false);
  const [user, setUser] = useState({
    package: 0,
    rank: 0,
    revenue: 0,
    is3x: false,
    newDep: 0,
    vUsdt: 0,
    start: 0,
    isNew: false
  })

  const _deposit = async (type) => {
    if(loading == true || loading2 == true) {
      return;
    }

    if(type == 1) {
      setLoading2(true);
    } else {
      setLoading(true);
    }

    try {
      let allowance = await useAppState.tokenContract.allowance(useAppState.walletAddress, useAppState.mainAddr);
      let allowance2 = await useAppState.tokenContract2.allowance(useAppState.walletAddress, useAppState.mainAddr);
      let allowance3 = await useAppState.tokenContract3.allowance(useAppState.walletAddress, useAppState.mainAddr);

      if(Number(useAppState.convert(allowance)) < Number(amount)) {
        const approval = await useAppState.tokenContract.approve(useAppState.mainAddr, ethers.utils.parseUnits('999999999', "mwei"));
        await approval.wait();
        message.success("Approved");
      } 

      if(Number(useAppState.convert(allowance2)) < Number(rtrAmt)) {
        const approval = await useAppState.tokenContract2.approve(useAppState.mainAddr, ethers.utils.parseUnits('999999999', "mwei"));
        await approval.wait();
        message.success("Approved");
      } 

      if(Number(useAppState.convert(allowance3)) < Number(oldRtrAmt)) {
        const approval = await useAppState.tokenContract3.approve(useAppState.mainAddr, ethers.utils.parseUnits('999999999', "mwei"));
        await approval.wait();
        message.success("Approved");
      } 

      if(Number(type) == 0) {
        let tx = await useAppState.contract.deposit(ethers.utils.parseUnits(amount, "mwei"),"0", [useOldToken, useToken, useVUsdt]);
        await tx.wait();

        message.success("Sucessfully Deposited");
      } else {
        let tx = await useAppState.contract.deposit(ethers.utils.parseUnits(amount, "mwei"),"1", [useOldToken, useToken, useVUsdt]);
        await tx.wait();

        message.success("Sucessfully Increased");
      }

      useAppState.setChange(useAppState.change + 1);
    } catch (error) {
      message.error(error.reason)
    }

    setLoading(false);
    setLoading2(false);
  }

  const deposit = async (type) => {
    if(loading == true || loading2 == true) {
      return;
    }

    if(type == 1) {
      setLoading2(true);
    } else {
      setLoading(true);
    }

    try {
      let allowance = await useAppState.tokenContract.allowance(useAppState.walletAddress, useAppState.mainAddr);
      let allowance2 = await useAppState.tokenContract2.allowance(useAppState.walletAddress, useAppState.mainAddr);
      let allowance3 = await useAppState.tokenContract3.allowance(useAppState.walletAddress, useAppState.mainAddr);

      if(Number(useAppState.convert(allowance)) < Number(amount)) {
        const approval = await useAppState.tokenContract.approve(useAppState.mainAddr, ethers.utils.parseUnits('999999999', "mwei"));
        await approval.wait();
        message.success("Approved");
      } 

      if(Number(useAppState.convert(allowance2)) <= Number(rtrAmt)) {
        const approval = await useAppState.tokenContract2.approve(useAppState.mainAddr, ethers.utils.parseUnits('999999999', "mwei"));
        await approval.wait();
        message.success("Approved");
      } 

      if(Number(useAppState.convert(allowance3)) < Number(oldRtrAmt)) {
        const approval = await useAppState.tokenContract3.approve(useAppState.mainAddr, ethers.utils.parseUnits('999999999', "mwei"));
        await approval.wait();
        message.success("Approved");
      } 

      let _userData = await useAppState.contract.userInfo(useAppState.walletAddress);
      let user = useAppState.walletAddress.toLowerCase()
      let _userDoc = await getDoc(doc(db, "rector", user));
      let ref = _userData.referrer.toLowerCase();
      let _refDoc = await getDoc(doc(db, "rector", ref));

      if(Number(type) == 0) {
        let tx = await useAppState.contract.deposit(ethers.utils.parseUnits(amount, "mwei"),"0", [useOldToken, useToken, useVUsdt]);
        await tx.wait();
        
        if(_userDoc.exists()) {
          await updateDoc(doc(db, "rector", user), {
            deposit: increment(Number(amount))
          })
        } else {
          await setDoc(doc(db, "rector", user), {
            address: user,
            deposit: Number(amount),
            directBusiness: 0
          })
        }

        if(_refDoc.exists()) {
          await updateDoc(doc(db, "rector", ref), {
            directBusiness: increment(Number(amount)),
          })
        } else {
          await setDoc(doc(db, "rector", ref), {
            address: ref,
            deposit: 0,
            directBusiness: Number(amount)
          })
        }

        message.success("Sucessfully Deposited");
      } else {
       
        let tx = await useAppState.contract.deposit(ethers.utils.parseUnits(amount, "mwei"),"1", [useOldToken, useToken, useVUsdt]);
        await tx.wait();

        if(_userDoc.exists()) {
          await updateDoc(doc(db, "rector", user), {
            deposit: increment(Number(amount))
          })
        } else {
          await setDoc(doc(db, "rector", user), {
            address: user,
            deposit: Number(amount),
            directBusiness: 0
          })
        }

        if(_refDoc.exists()) {
          await updateDoc(doc(db, "rector", ref), {
            directBusiness: increment(Number(amount)),
          })
        } else {
          await setDoc(doc(db, "rector", ref), {
            address: ref,
            deposit: 0,
            directBusiness: Number(amount)
          })
        }

        message.success("Sucessfully Increased");
      }

      useAppState.setChange(useAppState.change + 1);
    } catch (error) {
      console.log(error);
      message.error(error.reason)
    }

    setLoading(false);
    setLoading2(false);
  }

  useEffect(() => {
    async function req() {
      let _bal = await useAppState.tokenContract.balanceOf(useAppState.walletAddress);
      let _bal2 = await useAppState.tokenContract2.balanceOf(useAppState.walletAddress);
      let _bal3 = await useAppState.tokenContract3.balanceOf(useAppState.walletAddress);
      setBal(useAppState.convert(_bal))
      setBal2(useAppState.convert(_bal2))
      setBal3(useAppState.convert(_bal3))
      let _user = await useAppState.contract.userInfo(useAppState.walletAddress);
      let _income = await useAppState.contract.incomeInfo(useAppState.walletAddress);
      setUser({
        package: useAppState.convert(_user.package),
        rank: Number(_user.rank),
        revenue: 0,
        is3x: Number(_user.rank) >= 1 ? true : false,
        revenue: useAppState.convert(_user.curPackageIncome),
        vUsdt: useAppState.convert(_income.vUsdt),
        start: Number(_user.start) * 1000,
        isNew: (Number(_user.start) * 1000) < 1734769463682 ? false : true
      })
    }
    req();
  },[useAppState.walletAddress, useAppState.change])

  useEffect(() => {
    async function getData() {
      setLoading3(true);
      if(Number(amount) > 0) {
        let quote = await useAppState.contract.getUserDepositRate(useAppState.walletAddress, ethers.utils.parseUnits(amount, "mwei"), [useOldToken, useToken, useVUsdt])
        setUsdAmt(useAppState.convert(quote[0]));
        setRtrAmt(useAppState.convert(quote[1]));
        setOldRtrAmt(useAppState.convert(quote[2]));
        setVUsdtAmt(useAppState.convert(quote[3]));
      } else {
        setRtrAmt(0);
      }
      setLoading3(false);
    }
    getData();
  }, [amount, useOldToken, useToken, useVUsdt])

  return (
    <>
    <div className='flex'>
      <Sidebar />
      <div className='w-full flex flex-col items-end'>
        <Header />
        <div className='p-4 w-full md:w-4/5'>
        <img src='/bgelem.png' className='fixed hidden md:block right-0 top-0 -z-10 opacity-90' />
        <img src='/bgmobelem.png' className='fixed w-full left-0 md:top-0 block md:hidden top-0 -z-10 opacity-100' />
          <div className='flex flex-col-reverse md:flex-row w-full mt-3'>
            <div className='w-full md:w-3/5 mt-8 md:mt-0 bg-gray-800 bg-opacity-25 rounded-xl'>
                <div className='flex justify-between py-3 px-4 bg-gray-800 bg-opacity-25 rounded-t-xl'>
                  <p className='flex flex-col items-center'>
                    <span className='text-lg font-bold flex justify-center items-center'>{bal.toFixed(2)} <img src='../usdt.png' className='h-5 ml-2' /></span>
                    <span className=''>USDT Balance</span>
                  </p>
                  <p className='flex flex-col items-center'>
                    <span className='text-lg font-bold flex justify-center items-center'>{bal2.toFixed(2)} <img src='../rtr.png' className='h-6 ml-2' /></span>
                    <span className=''>RTR Balance</span>
                  </p>
                </div>

                <div className='flex w-full px-4 mt-4 font-semibold justify-between'>
                  <p>Current Package</p>
                  <p className='text-lime-500'>${user.package}</p>
                </div>
                <div className='flex w-full px-4 mt-4 font-semibold justify-between'>
                  <p>Rank</p>
                  <p className='text-lime-500'>{useAppState.lvlName[user.rank]}</p>
                </div>

                <div className='w-full px-4 mt-4 rounded-lg'>
                  <p className='font-semibold'>Income Cap (${user.revenue.toFixed(2)}/${user.package * (user.is3x ? 3 : 2)})</p>
                  <div className='mt-2 w-full bg-gray-300 h-2'><div style={{width: `${(user.revenue * 100)/(user.package * (user.is3x ? 3 : 2))}%`}} className='bg-pink-500 h-full max-w-full'></div></div>
                </div>              

                <div className='border border-[#76818b] border-opacity-50 w-full mt-8'></div>
                <p className='flex justify-between items-end px-2 md:px-4 py-3 rounded-lg mt-1'>
                  <input onChange={(e) => setAmount(e.target.value)} value={amount} className='text-lg outline-none font-medium' style={{background: 'none'}} placeholder='Enter amount in usdt' />
                </p>

                {Number(amount) > 0 ? (loading3 ? <div className='flex justify-center w-full'><ThreeDots color='green' height={8} /></div> : <span className='text-green-500 flex gap-x-2 justify-center w-full items-center'><span className='p-1 bg-gray-800 bg-opacity-40 rounded-sm font-semibold text-white'>{Number(usdAmt).toFixed(2)} USDT</span> <span className='p-1 bg-gray-800 bg-opacity-40 rounded-sm font-semibold text-white'>{Number(rtrAmt).toFixed(2)} RTR 2.0</span> <span className='p-1 bg-gray-800 bg-opacity-40 rounded-sm font-semibold text-white'>{Number(oldRtrAmt).toFixed(2)} RTR</span> </span>) : null}

                <div className='border border-[#76818b] border-opacity-50 w-full mt-2'></div>

              <div className='flex pl-3 flex-col items-start justify-center'>
                <Checkbox className=" text-white mt-2 font-semibold" onChange={(e) => setUseToken(e.target.checked)}><div><div>Use RTR 2.0 Tokens for Deposit. Max-40%</div> <div className='text-green-500'>- {bal2.toFixed(2)} RTR 2.0</div></div></Checkbox>
                <Checkbox className=" text-white mt-2 font-semibold" onChange={(e) => setUseOldToken(e.target.checked)}><div><div>Use Classic RTR Token for Deposit. Max-10%</div> <div className='text-green-500'>- {bal3.toFixed(2)} RTR</div></div></Checkbox>
                {/* <Checkbox className=" text-white mt-2 font-semibold" onChange={(e) => setuseVUsdt(e.target.checked)}><div><div>Use VUSDT Token for Deposit.</div> <div className='text-green-500'>- ${user.vUsdt.toFixed(2)} VUSDT</div></div></Checkbox> */}
              </div>

                <div className='mt-6 w-full flex justify-center mb-5'>
                  <button onClick={() => {user.isNew ? _deposit(1) : deposit(1)}} className='px-8 md:px-16 py-4 text-sm font-semibold rounded-md renew-btn'>{loading2 ? <TailSpin height={15} color='white' /> : "Increase"}</button>
                  {user.revenue >= user.package * (user.is3x ? 3 : 2) ? <button onClick={() => {user.isNew ? _deposit(0) : deposit(0)}} className='ml-4 px-8 md:px-16 py-4 text-sm font-semibold rounded-md bg-[#0ddc85] text-black'>{loading ? <TailSpin height={15} color='white' /> : "Confirm"}</button> : null}
                </div>
            </div>

            <div className='w-full flex flex-col items-center md:w-2/5 ml-0 md:ml-6'>
              <h1 className='mont text-lg text-teal-500 font-bold'>Rector Ranks</h1>
              <img src='../cycles.png' className='h-72 mt-4' />
            </div>
          </div>

          {/* <div className='flex flex-col w-full mt-6'>
              <h1 className="mb-2 font-bold text-white"><span className="text-green-500">Deposit</span> History</h1>
              <DepositHistory />
          </div> */}

        </div>
      </div>
    </div>
    </>
  )
}

export default Deposit
