import { useContext, useEffect, useState, useRef } from 'react';
import {Header, Sidebar} from './index';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Appstate } from '../App';
import { useSearchParams, useLocation, Link, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { Modal } from 'antd';
import { TailSpin } from 'react-loader-spinner';
import moment from 'moment';
import { useTimer } from 'react-timer-hook';
import { Button } from '@mui/material'
import FileCopyIcon from '@mui/icons-material/FileCopy';
import React from 'react'
import { SocialIcon } from 'react-social-icons';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import GroupsIcon from '@mui/icons-material/Groups';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import Marquee from 'react-fast-marquee';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';

function MyTimer2({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days
  } = useTimer({ expiryTimestamp});

  return <p><span>{days} Days, </span><span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
}

const Dashboard = () => {
  const useAppState = useContext(Appstate);
  const [user, setUser] = useState({
    revenue: 0,
    referrer: "",
    start: 0,
    usdt: 0,
    level: "NEWBIE",
    time: moment().add(10, "days").valueOf(),
    package: 0,
    is3x: false,
    totalRevenue: 0,
    lastClaimed: 0,
    uplineLastClaimed: 0,
    rank: 0,
    roiPack: 0,
    isNew: false
  });
  const [data, setData] = useState({
    totalTeam: 0,
    totalBusiness: 0,
    directTeam: 0,
    directBusiness: 0,
  })
  const intervalRef3 = useRef(null);
  const intervalRef2 = useRef(null);
  const intervalRef = useRef(null);
  const [time, setTime] = useState(0);
  const [time2, setTime2] = useState(0);
  const [time3, setTime3] = useState(0);
  const [referrer, setReferrer] = useState("");
  const [searchParams] = useSearchParams();
  const router = useLocation();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false)
  const [img, setImg] = useState("newbie");
  const [orders, setOrders] = useState([{
    address: "",
    amount: 0
  }])
  const [royalty, setRoyalty] = useState(0);
  const [lastDist, setLastDist] = useState(0);
  const [royaltyTime, setRoyaltyTime] = useState(0);
  const [royaltyUsers, setRoyaltyUsers] = useState([]);
  const [monthly, setMonthly] = useState(0);
  const [monthlyLastDist, setMonthlyLastDist] = useState(0);
  const [monthlyTime, setMonthlyTime] = useState(0);
  const [monthlyUsers, setMonthlyUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [roi, setRoi] = useState(0);
  const [uplineRoi, setUplineRoi] = useState(0);
  const [isRankAvl, setRankAvl] = useState(false);
  const [rankLoading, setRankLoading] = useState(false);
  const [selfLoading, setSelfLoading] = useState(false);
  const [uplineLoading, setUplineLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [newDep, setNewDep] = useState(0);
  const [newBusiness, setNewBusiness] = useState(0);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if(Number(user.start) > 0) {
        setTime(Date.now() - Number(user.start) * 1000);
      } else {
        setTime(0);
      }
    }, 10);
    return () => clearInterval(intervalRef.current);
  }, [user.start]);

  useEffect(() => {
    intervalRef2.current = setInterval(() => {
      if(Number(user.lastClaimed) > 0) {
        setTime2(Date.now() - Number(user.lastClaimed) * 1000);
      } else {
        setTime2(0);
      }
    }, 10);
    return () => clearInterval(intervalRef2.current);
  }, [user.lastClaimed]);

  useEffect(() => {
    intervalRef3.current = setInterval(() => {
      if(Number(user.uplineLastClaimed) > 0) {
        setTime3(Date.now() - Number(user.uplineLastClaimed) * 1000);
      } else {
        setTime3(0);
      }
    }, 10);
    return () => clearInterval(intervalRef3.current);
  }, [user.lastClaimed]);

  useEffect(() => {
    let ref = searchParams.get("ref");
    if(ref !== null) {
      setReferrer(ref)
    } else {
      setReferrer("Invalid Referrer Link")
    }
  }, [router]);
  
  useEffect(() => {
    async function getData() {
      const _user = await useAppState.contract.userInfo(useAppState.walletAddress);

      if(_user.referrer == "0x0000000000000000000000000000000000000000" && Number(useAppState.convert(_user.package)) == 0) {
        setVisible(true);
      } else {
        setVisible(false);
      }

      const _bal = await useAppState.tokenContract.balanceOf(useAppState.walletAddress);
      let _income = await useAppState.contract.incomeInfo(useAppState.walletAddress);
      setImg((useAppState.lvlName[Number(_user.rank)]).toLowerCase());
      setUser({
        package: useAppState.convert(_user.package),
        revenue: useAppState.convert(_user.curPackageIncome),
        referrer: _user.referrer,
        start: Number(_user.start),
        usdt: useAppState.convert(_bal),
        level: useAppState.lvlName[Number(_user.rank)],
        is3x: Number(_user.rank) >= 1 ? true : false,
        totalRevenue: useAppState.convert(_income.totalRevenue),
        lastClaimed: Number(_user.lastClaimed),
        rank: Number(_user.rank),
        roiPack: useAppState.convert(_income.roiPack),
        isNew: (Number(_user.start) * 1000) < 1734769463682 ? false : true
      })

      if(Number(_user.start) > 0) {
        let _roi = await useAppState.contract.getClaimableROI(useAppState.walletAddress);
        setRoi(useAppState.convert(_roi));
        setUser((prev) => ({...prev, revenue: (useAppState.convert(_user.curPackageIncome))}))
      }
      
      let _rankavl = await useAppState.contract.isUpdateRankAvl(useAppState.walletAddress);
      setRankAvl(_rankavl);

      let _royalty = await useAppState.contract.royalty();
      let _royaltyLastDist = await useAppState.contract.royaltyLastDistributed();
      let _royaltyTime = await useAppState.contract.getRoyaltyDistTime()
      let _royaltyUsers = await useAppState.contract.getRoyaltyUsers();
      setRoyalty(useAppState.convert(_royalty));
      setLastDist(Number(_royaltyLastDist));
      setRoyaltyTime(Number(_royaltyTime));
      setRoyaltyUsers(_royaltyUsers);

      let _monthly = await useAppState.contract.monthly();
      let _monthlyLastDist = await useAppState.contract.monthlyLastDistributed();
      let _monthlyTime = await useAppState.contract.getMonthlyDistTime()
      let _monthlyUsers = await useAppState.contract.getMonthlyUsers();
      setMonthly(useAppState.convert(_monthly));
      setMonthlyLastDist(Number(_monthlyLastDist));
      setMonthlyTime(Number(_monthlyTime));
      setMonthlyUsers(_monthlyUsers);

      setShow(true);

      let _userDoc = await getDoc(doc(db, "rector", useAppState.walletAddress.toLowerCase()));
      if(_userDoc.exists()) {
        setNewDep(_userDoc.data()['deposit']);
        setNewBusiness(_userDoc.data()['directBusiness']);
      }

      let _data = await useAppState.contract.getTeamBusiness(useAppState.walletAddress);
      setData({
        totalTeam: _user.totalTeam,
        totalBusiness: useAppState.convert(_data[2]),
        directTeam: Number(_user.directTeam),
        directBusiness: useAppState.convert(_user.directBusiness),
      })
    }
    getData();
  },[useAppState.walletAddress, useAppState.change])

  const register = async () => {
    setLoading(true);
    try {
      let tx = await useAppState.contract.register(referrer);
      await tx.wait();
      useAppState.setChange(useAppState.change + 1);
      message.success("Sucessfully Registered");
    } catch (error) {
      message.error(error.reason)
    }
    setLoading(false);
  }

  const formatTime2 = (time) => {
    const sec = Math.floor((time / 1000) % 60);
    const min = Math.floor((time / 60000) % 60);
    const hr = Math.floor((time / 3600000) % 24);
      return (
        `${hr.toString().padStart(2, '0')} : ` +
        `${min.toString().padStart(2, '0')} : ` +
        `${sec.toString().padStart(2, '0')}`
      );
  };

  const claimSelf = async () => {
    setSelfLoading(true);
    try {
      let tx = await useAppState.contract.claimROI(useAppState.walletAddress);
      await tx.wait();
      useAppState.setChange(useAppState.change + 1);
      message.success("Sucessfully Claimed");
    } catch (error) {
      message.error(error.reason);
    }
    setSelfLoading(false);
  }

  const updateRank = async () => {
    setRankLoading(true);
    try {
      let tx = await useAppState.contract.updateRank(useAppState.walletAddress);
      await tx.wait();
      setRankAvl(false);
      useAppState.setChange(useAppState.change + 1);
      message.success("Sucessfully Updated");
    } catch (error) {
      message.error(error.reason);
    }
    setRankLoading(false);
  }

  const footer = [
    <button className='px-4 py-2 rounded-2xl font-medium text-sm bg-green-500 text-black' onClick={register}>
      {loading ? <TailSpin height={18} color='black' /> : "Confirm"}
    </button>,
  ];

  return (
    <>
    <div className='flex'>
      <Modal
          title="Register"
          open={visible}
          footer={footer}
          closable={false}
          maskClosable={false}
          confirmLoading={true}
        >
          <p className='break-all'>Ref: {referrer}</p>
      </Modal>
      <Sidebar />
      <div className='w-full flex flex-col items-end'>
        <Header />
        <div className='p-4 w-full md:w-4/5'>
        <img src='../bgelem.png' className='fixed hidden md:block right-0 top-0 -z-10 opacity-90' />
        <img src='../bgmobelem.png' className='fixed w-full left-0 md:top-0 block md:hidden top-0 -z-10 opacity-100' />
          <h1 className='font-bold text-2xl'>Your <span className='text-violet-500'>Dashboard</span></h1>

          {/* Info */}
          {/* <Marquee className=''>
            🚀 RTR 2.0 is Now Live!
          </Marquee> */}
          <div className="flex justify-center items-center  p-2 mt-3 rounded-lg bg-gray-800 bg-opacity-40">
            <div className="shadow-lg rounded-lg p-6 w-full border border-gray-600 bg-gray-800 bg-opacity-25">
              <h1 className="text-2xl font-bold text-center text-blue-600 mb-4">
                Important Announcement 🔊
              </h1>
              <p>
                <em className='break-all'>Old users transitioning from RTR to RTR 2.0 are required to deposit 30% of their previous deposit. However, starting 1st February 2025, this requirement will increase to 50% instead of 30% to withdraw funds.</em>
              </p>

              <p className="text-center text-gray-300 mt-4">
                <em className='break-all'>Don't miss this limited-time offer! Avail it now!</em>
              </p>

              <p className="text-center text-gray-300 mt-4">
                <em className='break-all'>RTR Address — 0x0FF15227EC8165B89365a1d3524E46C48BAD73FB</em>
              </p>
              <p className="text-center text-gray-300 mt-4">
                <em className='break-all'>RTR 2.0 Address — 0x0863AAdAA1d818C9BFac5a10eAc5A323969E6c98</em>
              </p>
              <p className="text-center text-gray-300 mt-4">
              </p>
              {/* <p className="text-center text-gray-300 mt-4">
                <em>Rector 2.0 Address — 0xE4D0909273C2e0991f0c4BB23f99dcBAf2D76353</em>
              </p> */}
            </div>
          </div>
          
          {user.isNew ? null : 
          <div className='mt-4'>
            <h1 className='font-semibold text-lg mt-2 animate-pulse'>Fresh Depsoit - ${newDep}</h1>
            <h1 className='font-semibold text-lg mt-2 animate-pulse'>Fresh Direct Business - ${newBusiness}</h1>
          </div>
          }

          <div className='w-full flex flex-col-reverse md:flex-row justify-between mt-8'>
            <div className='mt-6 md:mt-3 py-4 flex flex-col bg-gray-800 bg-opacity-25 items-center relative rounded-xl h-[460px] px-2 md:px-6 w-full md:w-1/2'>
              <img src='../tiffany-blur.webp' className='absolute top-0 h-full w-full left-0 -z-10 rounded-xl opacity-60' />
              <h1 className='font-bold text-xl'><span className='text-violet-500'>Basic</span> Info</h1>

              <div className='bg-gray-700 bg-opacity-40 w-full px-2 py-3 mt-3 rounded-lg'>
                <p className='font-semibold'>Package (${user.package}/$10,000)</p>
                <div className='mt-2 w-full bg-gray-300 h-2'><div style={{width: `${(user.package * 100)/10000}%`}} className='bg-green-500 h-full'></div></div>
              </div>              

              <div className='bg-gray-700 bg-opacity-40 w-full px-2 py-3 mt-3 rounded-lg'>
                <p className='font-semibold'>Income Cap (${user.revenue.toFixed(2)}/${user.package * (user.is3x ? 3 : 2)})</p>
                <div className='mt-2 w-full bg-gray-300 h-2'><div style={{width: `${(user.revenue * 100)/(user.package * (user.is3x ? 3 : 2))}%`}} className='bg-pink-500 h-full max-w-full'></div></div>
              </div>              

              <div className='mt-3 flex justify-between w-full'>
                <div className='flex justify-center items-center flex-col bg-gray-700 bg-opacity-40 rounded-lg w-full p-3'>
                  <img src='roi.png' className='h-20' placeholder='roi' />
                  <p className='text-sm font-bold'>ROI Generated</p>
                  <p className='text-lg text-lime-500 font-bold'>${roi} / ${user.roiPack}</p>
                  <p className='text-lime-500 mb-2'>{useAppState.formatTime(time2)}</p>
                  {Number(roi) > 0 ? <Button onClick={claimSelf} variant='contained' className='w-full -z-0' color='warning'>
                    <p className='font-bold capitalize'>{selfLoading ? <TailSpin color='white' height={20} /> : "Claim"}</p>
                  </Button> : null}
                </div>
              </div>
            </div>

            <div className='mt-3 flex flex-col items-center py-4 relative rounded-xl h-[450px] px-4 w-full md:w-1/3'>
              <img src='../p-background.png' className='absolute top-0 -z-30 left-0 h-full w-full' />
              <img src={`../${img}.png`} className='h-32' />
              <p className='text-lime-500 font-semibold text-lg font-serif'>{user.level}</p>
              {isRankAvl ? <p onClick={updateRank} className='bg-green-500 text-black px-4 py-1 animate-pulse font-semibold font-sans text-sm rounded-lg'>{rankLoading ? <TailSpin height={12} color='white' /> : "Update"}</p>: null}
              <div className='flex w-full mt-4 font-semibold justify-between'>
                <p>Package</p>
                <p className='text-lime-500'>${user.package}</p>
              </div>
              <div className='flex w-full mt-4 font-semibold justify-between'>
                <p>Revenue</p>
                <p className='text-lime-500'>${user.totalRevenue.toFixed(2)}</p>
              </div>
              <div className='flex w-full mt-4 font-semibold justify-between'>
                <p>Balance</p>
                <p className='text-lime-500 flex justify-center items-center'>{Number(user.usdt).toFixed(2)} <img src='../usdt.png' className='h-4 ml-2' /></p>
              </div>
              <div className='flex w-full mt-4 font-semibold justify-between'>
                <p>Referrer</p>
                <p className='text-lime-500'>{user.referrer.slice(0, 6)}...{user.referrer.slice(38)}</p>
              </div>
              <div className='flex w-full mt-4 font-semibold justify-between'>
                <p>Started Since</p>
                <p className='text-lime-500'>{useAppState.formatTime(time)}</p>
              </div>
              <div className='flex w-full mt-4 font-semibold justify-between'>
                <p>Refer Link</p>
                <CopyToClipboard text={`https://rector.world//dashboard?ref=${useAppState.walletAddress}`} >
                  <p onClick={() => message.success("Copied")} className='break-all w-2/3 text-lime-500 rounded-md text-sm cursor-pointer'>https://rector.world/dashboard?ref={useAppState.walletAddress.slice(0, 6)}...{useAppState.walletAddress.slice(33)} <span className='ml-2'><FileCopyIcon color='primary' /></span></p>
                </CopyToClipboard>
              </div>
            </div>
          </div>


          {/* Dashboard Goto */}
          <div className='flex md:flex-row flex-col justify-between mt-8 rounded-sm'>
            <div className='bg-gray-900 bg-opacity-25 border border-gray-700 md:w-1/2 w-full mt-4 md:mt-0 flex p-3 items-center justify-center flex-col'>
                <img className='h-36 mb-2' src='../deposit.png' alt='deposit' />
                <Link className='w-2/3' to={'/deposit'}><Button variant='contained' className='w-full -z-0' color='success'>
                  <p className='font-bold capitalize'>Deposit</p>
                </Button></Link>
              </div>
              <div className='bg-gray-900 bg-opacity-25 p-3 border border-gray-700 md:w-1/2 w-full mt-4 md:mt-0 flex items-center justify-center flex-col'>
                <img className='h-36 mb-2' src='../withdraw.png' alt='withdraw' />
                <Link className='w-2/3' to={'/wallet'}><Button variant='contained' className='w-full -z-0' color="warning" >
                  <p className='font-bold capitalize'>Withdraw</p>
                </Button></Link>
              </div>
            </div>


          <div className='mt-10 bg-gray-700 pink_blur bg-opacity-25 rounded-lg py-6 relative'>
            <div className='flex flex-col md:flex-row justify-center items-center  w-full px-4'>
              <div className='flex flex-col items-center relative h-40 w-full rounded-xl md:w-[400px]'>
                <p className='text-2xl font-bold text-blue-500'>Premium Pool</p>
                <p className='text-xs text-white font-semibold mt-2'>Last Distributed on {new Date(Number(lastDist) * 1000).toLocaleString()}</p>
                <p className='text-3xl text-pink-500 font-bold flex justify-center items-center mt-1'>${Number(royalty).toFixed(2)} </p>
                {/* <p className='text-xs mt-1 text-[#e67deb] font-semibold'>Pool Time Remaining</p> */}
                {show ? <p className='text-xl font-mono font-semibold px-4 mt-2 py-2 rounded-lg bg-gray-700 bg-opacity-25'><MyTimer2 expiryTimestamp={Number(royaltyTime) * 1000} /></p> : null}
              </div>
            </div>

            {royaltyUsers.length > 0 ? <h1 className='font-semibold px-4 mt-6 md:px-8'>Premium <span className='text-[#f0c259]'>Achievers</span></h1> : null}
            {royaltyUsers.length > 0 ?
            <div className="overflow-auto h-52 md:h-36 px-0 md:px-8 flex justify-between w-full mt-2">
              <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">Position</p>
                {royaltyUsers.map((e, i) => {
                  return <p key={i} className="whitespace-nowrap  p-1">{i+1}</p>;
                })}
              </div>

              <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">Address</p>
                {royaltyUsers.map((e, i) => {
                  return <p key={i} className="whitespace-nowrap  p-1">{e.slice(0,8)}...{e.slice(38)}</p>;
                })}
              </div>

              <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">Reward</p>
                {royaltyUsers.map((e, i) => {
                  return <p key={i} className="whitespace-nowrap flex justify-center items-center p-1">{Number(royalty/royaltyUsers.length).toFixed(2)} <img src='../usdt.png' className='h-4 ml-2' /></p>;
                })}
              </div>

            </div>
          : null}
          </div>

          <div className='mt-10 bg-gray-700 red_blur bg-opacity-25 rounded-lg py-6 relative'>
            <div className='flex flex-col md:flex-row justify-center items-center  w-full px-4'>
              <div className='flex flex-col items-center relative h-40 w-full rounded-xl md:w-[400px]'>
                <p className='text-2xl font-bold text-blue-500'>Royalty Pool</p>
                <p className='text-xs text-white font-semibold mt-2'>Last Distributed on {new Date(Number(monthlyLastDist) * 1000).toLocaleString()}</p>
                <p className='text-3xl text-pink-500 font-bold flex justify-center items-center mt-1'>${Number(monthly).toFixed(2)} </p>
                {/* <p className='text-xs mt-1 text-[#e67deb] font-semibold'>Pool Time Remaining</p> */}
                {show ? <p className='text-xl font-mono font-semibold px-4 mt-2 py-2 rounded-lg bg-gray-700 bg-opacity-25'><MyTimer2 expiryTimestamp={Number(monthlyTime) * 1000} /></p> : null}
              </div>
            </div>

            {monthlyUsers.length > 0 ? <h1 className='font-semibold px-4 mt-6 md:px-8'>Royalty <span className='text-[#f0c259]'>Achievers</span></h1> : null}
            {monthlyUsers.length > 0 ?
            <div className="overflow-auto h-52 md:h-36 px-0 md:px-8 flex justify-between w-full mt-2">
              <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">Position</p>
                {monthlyUsers.map((e, i) => {
                  return <p key={i} className="whitespace-nowrap  p-1">{i+1}</p>;
                })}
              </div>

              <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">Address</p>
                {monthlyUsers.map((e, i) => {
                  return <p key={i} className="whitespace-nowrap  p-1">{e.slice(0,8)}...{e.slice(38)}</p>;
                })}
              </div>

              <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">Reward</p>
                {monthlyUsers.map((e, i) => {
                  return <p key={i} className="whitespace-nowrap flex justify-center items-center p-1">{Number(monthly/monthlyUsers.length).toFixed(2)} <img src='../usdt.png' className='h-4 ml-2' /></p>;
                })}
              </div>

            </div>
          : null}
          </div>

          <div className='mt-8 mb-10'>
            <h1 className='font-bold text-2xl mt-3 px-2 md:px-0'>Team <span className='text-violet-500'>Info</span></h1>

            {/* Teams Details */}
            <div className='flex flex-col md:flex-row mt-8 px-4'>
              <div className='flex justify-between w-full md:w-1/2 px-4 bg-gray-800 bg-opacity-30 relative py-4 rounded-lg'>
                <img src='../tiffany-blur.webp' className='absolute top-0 h-full w-full left-0 -z-10 opacity-60 rounded-lg' />
                <div className='flex flex-col'>
                  <span className='font-semibold'>Total Team</span>
                  <span className='font-semibold text-lg flex items-center'>{Number(data.totalTeam)}</span>
                </div>
                <p className='text-green-500'><Diversity2Icon fontSize='large' /></p>
              </div>
              <div className='flex ml-0 md:ml-4 mt-4 md:mt-0 justify-between w-full md:w-1/2 px-4 bg-gray-800 bg-opacity-30 relative py-4 rounded-lg'>
                <img src='../tiffany-blur.webp' className='absolute top-0 h-full w-full left-0 -z-10 opacity-60 rounded-lg' />
                <div className='flex flex-col'>
                  <span className='font-semibold'>Direct Team</span>
                  <span className='font-semibold text-lg flex items-center'>{Number(data.directTeam)}</span>
                </div>
                <p className='text-green-500'><CallSplitIcon fontSize='large' className='rotate-180' /></p>
              </div>
            </div>
            <div className='flex flex-col-reverse md:flex-row mt-4 px-4'>
              <div className='flex justify-between mt-4 md:mt-0 w-full md:w-1/2 px-4 bg-gray-800 bg-opacity-30 relative py-4 rounded-lg'>
                <img src='../tiffany-blur.webp' className='absolute top-0 h-full w-full left-0 -z-10 opacity-60 rounded-lg' />
                <div className='flex flex-col'>
                  <span className='font-semibold'>Total Business</span>
                  <span className='font-semibold text-lg flex items-center'>${data.totalBusiness} <img src="/usdt.png" className="h-4 ml-2" /></span>
                </div>
                <p className='text-green-500'><GroupsIcon fontSize='large' /></p>
              </div>
              <div className='flex ml-0 md:ml-4 justify-between w-full md:w-1/2 px-4 bg-gray-800 bg-opacity-30 relative py-4 rounded-lg'>
                <img src='../tiffany-blur.webp' className='absolute top-0 h-full w-full left-0 -z-10 opacity-60 rounded-lg' />
                <div className='flex flex-col'>
                  <span className='font-semibold'>Direct Business</span>
                  <span className='font-semibold text-lg flex items-center'>${data.directBusiness} <img src="/usdt.png" className="h-4 ml-2" /></span>
                </div>
                <p className='text-green-500'><AltRouteIcon fontSize='large' className='rotate-180'  /></p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    </>
  )
}

export default Dashboard
