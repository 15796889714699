import React, { useContext, useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { TailSpin } from "react-loader-spinner";
import { Appstate } from "../App";
import { message } from "antd";
import { ethers } from "ethers";
import { useTimer } from "react-timer-hook";
import moment from 'moment'

function MyTimer2({ expiryTimestamp }) {
  const { seconds, minutes, hours, days } = useTimer({ expiryTimestamp });

  return (
    <p>
      <span>{days} Days, </span>
      <span>{hours.toString().padStart(2, "0")}</span>:
      <span>{minutes.toString().padStart(2, "0")}</span>:
      <span>{seconds.toString().padStart(2, "0")}</span>
    </p>
  );
}

const Admin = () => {
  const useAppState = useContext(Appstate);
  const [royalty, setRoyalty] = useState(0);
  const [lastDist, setLastDist] = useState(0);
  const [royaltyTime, setRoyaltyTime] = useState(0);
  const [royaltyUsers, setRoyaltyUsers] = useState([]);
  const [monthly, setMonthly] = useState(0);
  const [monthlyLastDist, setMonthlyLastDist] = useState(0);
  const [monthlyTime, setMonthlyTime] = useState(0);
  const [monthlyUsers, setMonthlyUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [oldAddr, setOldAddr] = useState("");
  const [newAddr, setNewAddr] = useState("");


  const [bal, setBal] = useState(0);
  const [sold, setSold] = useState(0);
  const [usdt, setUsdt] = useState(0);
  const [amount, setAmount] = useState(0);
  const [lockTime, setLockTime] = useState(0);

  useEffect(() => {
    async function getData() {
      let _royalty = await useAppState.contract.royalty();
      let _royaltyLastDist = await useAppState.contract.royaltyLastDistributed();
      let _royaltyTime = await useAppState.contract.getRoyaltyDistTime();
      let _royaltyUsers = await useAppState.contract.getRoyaltyUsers();
      setRoyalty(useAppState.convert(_royalty));
      setLastDist(Number(_royaltyLastDist));
      setRoyaltyTime(Number(_royaltyTime));
      setRoyaltyUsers(_royaltyUsers);

      let _monthly = await useAppState.contract.monthly();
      let _monthlyLastDist = await useAppState.contract.monthlyLastDistributed();
      let _monthlyTime = await useAppState.contract.getMonthlyDistTime()
      let _monthlyUsers = await useAppState.contract.getMonthlyUsers();
      setMonthly(useAppState.convert(_monthly));
      setMonthlyLastDist(Number(_monthlyLastDist));
      setMonthlyTime(Number(_monthlyTime));
      setMonthlyUsers(_monthlyUsers);

      setShow(true);

      let _bal = await useAppState.tokenContract.balanceOf(useAppState.backupFunds);
      setBal(useAppState.convert(_bal));
      let _sold = await useAppState.backup.soldRTR();
      setSold(useAppState.convert(_sold));
      if(Number(_sold) > 0) {
        let _usdt = await useAppState.contract.callStatic.getQuoteInUSDT(_sold);
        setUsdt(useAppState.convert(_usdt));
      }
    }
    getData();
  }, [useAppState.change]);

  const distributeRoyalty = async () => {
    setLoading(true);
    try {
      let tx = await useAppState.contract.distributeRoyalty();
      await tx.wait();
      useAppState.setChange(useAppState.change + 1);
      message.success("Distributed");
    } catch (error) {
      message.error(error.reason);
    }
    setLoading(false);
  };

  const distributeMonthly = async () => {
    setLoading3(true);
    try {
      let tx = await useAppState.contract.distributeMonthly();
      await tx.wait();
      useAppState.setChange(useAppState.change + 1);
      message.success("Distributed");
    } catch (error) {
      message.error(error.reason);
    }
    setLoading3(false);
  };

  const buyFromBackup = async () => {
    setLoading2(true);
    try {
      let tx = await useAppState.backup.buyFromReserve(ethers.utils.parseUnits(amount, "mwei"));
      await tx.wait();
      useAppState.setChange(useAppState.change + 1);
      message.success("Backup Used");
    } catch (error) {
      message.error(error.reason);
    }
    setLoading2(false);
  };

  const changeAddr = async () => {
    setLoading4(true);
    try {
      let tx = await useAppState.contract.setAddr(0, oldAddr, newAddr, 0, 0,0,0);
      await tx.wait();
      useAppState.setChange(useAppState.change + 1);
      message.success("changed");
    } catch (error) {
      message.error(error.reason);
    }
    setLoading4(false);
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="w-full flex flex-col items-end">
        <img
          src="bgelem.png"
          className="fixed hidden md:block right-0 top-0 -z-10 opacity-100"
        />
        <img
          src="bgmobelem.png"
          className="fixed w-full left-0 md:top-0 block md:hidden top-0 -z-10 opacity-100"
        />
        <Header />

        <div className="px-3 py-1 w-full md:w-4/5 mt-6">
          <h1 className="font-bold text-2xl">
            Rector <span className="text-violet-500">Management</span>
          </h1>
          <div className="mt-4 bg-gray-700 w-full red_blur bg-opacity-25 rounded-lg py-6 relative">
            <div className="flex flex-col md:flex-row justify-center items-center  w-full px-4">
              <div className="flex flex-col items-center relative h-40 w-full rounded-xl md:w-[400px]">
                <p className="text-2xl font-bold text-blue-500">
                  Premium Pool{" "}
                  <button
                    onClick={distributeRoyalty}
                    className="py-2 px-4 bg-gray-700 bg-opacity-25 text-sm font-semibold rounded-xl text-lime-500"
                  >
                    {loading ? (
                      <TailSpin color="white" height={16} />
                    ) : (
                      "Distribute"
                    )}
                  </button>
                </p>
                <p className="text-xs text-white font-semibold mt-2">
                  Last Distributed on{" "}
                  {new Date(Number(lastDist) * 1000).toLocaleString()}
                </p>
                <p className="text-3xl text-pink-500 font-bold flex justify-center items-center mt-1">
                  ${Number(royalty).toFixed(2)}{" "}
                </p>
                {/* <p className='text-xs mt-1 text-[#e67deb] font-semibold'>Pool Time Remaining</p> */}
                {show ? (
                  <p className="text-xl font-mono font-semibold px-4 mt-2 py-2 rounded-lg bg-gray-700 bg-opacity-25">
                    <MyTimer2 expiryTimestamp={Number(royaltyTime) * 1000} />
                  </p>
                ) : null}
              </div>
            </div>

            {royaltyUsers.length > 0 ? (
              <h1 className="font-semibold px-4 mt-6 md:px-8">
                Premium <span className="text-[#f0c259]">Achievers</span>
              </h1>
            ) : null}
            {royaltyUsers.length > 0 ? (
              <div className="overflow-auto h-52 md:h-36 px-0 md:px-8 flex justify-between w-full mt-2">
                <div className="whitespace-nowrap ml-4 md:ml-0">
                  <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">
                    Position
                  </p>
                  {royaltyUsers.map((e, i) => {
                    return (
                      <p key={i} className="whitespace-nowrap  p-1">
                        {i + 1}
                      </p>
                    );
                  })}
                </div>

                <div className="whitespace-nowrap ml-4 md:ml-0">
                  <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">
                    Address
                  </p>
                  {royaltyUsers.map((e, i) => {
                    return (
                      <p key={i} className="whitespace-nowrap  p-1">
                        {e.slice(0, 8)}...{e.slice(38)}
                      </p>
                    );
                  })}
                </div>

                <div className="whitespace-nowrap ml-4 md:ml-0">
                  <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">
                    Reward
                  </p>
                  {royaltyUsers.map((e, i) => {
                    return (
                      <p
                        key={i}
                        className="whitespace-nowrap flex justify-center items-center p-1"
                      >
                        {Number(royalty / royaltyUsers.length).toFixed(2)}{" "}
                        <img src="../usdt.png" className="h-4 ml-2" />
                      </p>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>

          <div className="mt-4 bg-gray-700 w-full red_blur bg-opacity-25 rounded-lg py-6 relative">
            <div className="flex flex-col md:flex-row justify-center items-center  w-full px-4">
              <div className="flex flex-col items-center relative h-40 w-full rounded-xl md:w-[400px]">
                <p className="text-2xl font-bold text-blue-500">
                  Royalty Pool{" "}
                  <button
                    onClick={distributeMonthly}
                    className="py-2 px-4 bg-gray-700 bg-opacity-25 text-sm font-semibold rounded-xl text-lime-500"
                  >
                    {loading3 ? (
                      <TailSpin color="white" height={16} />
                    ) : (
                      "Distribute"
                    )}
                  </button>
                </p>
                <p className="text-xs text-white font-semibold mt-2">
                  Last Distributed on{" "}
                  {new Date(Number(monthlyLastDist) * 1000).toLocaleString()}
                </p>
                <p className="text-3xl text-pink-500 font-bold flex justify-center items-center mt-1">
                  ${Number(monthly).toFixed(2)}{" "}
                </p>
                {/* <p className='text-xs mt-1 text-[#e67deb] font-semibold'>Pool Time Remaining</p> */}
                {show ? (
                  <p className="text-xl font-mono font-semibold px-4 mt-2 py-2 rounded-lg bg-gray-700 bg-opacity-25">
                    <MyTimer2 expiryTimestamp={Number(monthlyTime) * 1000} />
                  </p>
                ) : null}
              </div>
            </div>

            {monthlyUsers.length > 0 ? (
              <h1 className="font-semibold px-4 mt-6 md:px-8">
                Royalty <span className="text-[#f0c259]">Achievers</span>
              </h1>
            ) : null}
            {monthlyUsers.length > 0 ? (
              <div className="overflow-auto h-52 md:h-36 px-0 md:px-8 flex justify-between w-full mt-2">
                <div className="whitespace-nowrap ml-4 md:ml-0">
                  <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">
                    Position
                  </p>
                  {monthlyUsers.map((e, i) => {
                    return (
                      <p key={i} className="whitespace-nowrap  p-1">
                        {i + 1}
                      </p>
                    );
                  })}
                </div>

                <div className="whitespace-nowrap ml-4 md:ml-0">
                  <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">
                    Address
                  </p>
                  {monthlyUsers.map((e, i) => {
                    return (
                      <p key={i} className="whitespace-nowrap  p-1">
                        {e.slice(0, 8)}...{e.slice(38)}
                      </p>
                    );
                  })}
                </div>

                <div className="whitespace-nowrap ml-4 md:ml-0">
                  <p className="whitespace-nowrap p-1 text-[#76818b] font-medium text-sm">
                    Reward
                  </p>
                  {monthlyUsers.map((e, i) => {
                    return (
                      <p
                        key={i}
                        className="whitespace-nowrap flex justify-center items-center p-1"
                      >
                        {Number(monthly / monthlyUsers.length).toFixed(2)}{" "}
                        <img src="../usdt.png" className="h-4 ml-2" />
                      </p>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
          
          {/* Backup */}
          <div className='mt-4 bg-gray-700 w-full pink_blur bg-opacity-25 rounded-lg p-4 relative text-lg font-semibold font-mono flex flex-col items-start'>
             <h1 className="flex justify-center items-center">Balance: <p className="text-lime-500 ml-2">${bal}</p></h1>
             <h1 className="flex justify-center items-center">Sold RTR: <p className="text-lime-500 ml-2">{sold} RTR</p></h1>
             <h1 className="flex justify-center items-center">USDT Required To Recover: <p className="text-lime-500 ml-2">${usdt + (usdt/100)}</p></h1>
             <input className="mt-3 px-4 py-2 rounded-md bg-white text-gray-700 font-medium font-mono" value={amount} onChange={(e) => setAmount(e.target.value)} placeholder="Enter Amount" />
             <button
               onClick={buyFromBackup}
               className="py-2 px-4 mt-2 bg-gray-700 bg-opacity-25 text-sm font-semibold rounded-xl text-blue-500"
             >
               {loading2 ? (
                 <TailSpin color="white" height={16} />
               ) : (
                 "Buy From Backup"
               )}
             </button>
          </div>

          {/* Conctract Admin Functions */}
          <input className='mt-6 w-full rounded-sm outline-none text-gray-800 p-2' value={oldAddr} onChange={(e) => setOldAddr(e.target.value)} placeholder='Enter Old Address' />
          <input className='mt-2 w-full rounded-sm outline-none text-gray-800 p-2' value={newAddr} onChange={(e) => setNewAddr(e.target.value)} placeholder='Enter New Address' />
          <button onClick={changeAddr} className='bg-lime-500 mb-2 mt-4 w-full flex justify-center font-semibold p-2 rounded-lg'>{loading4 ? <TailSpin height={22} color='white' /> : "Change"}</button>


        </div>
      </div>
    </div>
  );
};

export default Admin;
