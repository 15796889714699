import { useContext, useEffect, useState } from 'react'
import {Sidebar, Header} from './index'
import { Appstate } from '../App'
import Diversity2Icon from '@mui/icons-material/Diversity2';
import { FileCopy } from '@mui/icons-material';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import GroupsIcon from '@mui/icons-material/Groups';
import { SocialIcon } from 'react-social-icons';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Button } from '@mui/material'
import {message} from 'antd'
import {TailSpin} from 'react-loader-spinner';

const Teams = () => {
  const useAppState = useContext(Appstate);
  const [rankRequired, setRankRequired] = useState([
    {
      rank: "Silver",
      selfDeposit: 100,
      directTeam: 5,
      directBusiness: 500
    },
    {
      rank: "Gold",
      selfDeposit: 300,
      directTeam: 10,
      directBusiness: 1000
    },
    {
      rank: "Platinium",
      selfDeposit: 500,
      lowerRank: 1,
      secondLowerRank: 2
    },
    {
      rank: "Emerald",
      selfDeposit: 1500,
      lowerRank: 1,
      secondLowerRank: 2
    },
    {
      rank: "Ruby",
      selfDeposit: 2000,
      lowerRank: 1,
      secondLowerRank: 2
    },
    {
      rank: "Diamond",
      selfDeposit: 5000,
      lowerRank: 1,
      secondLowerRank: 2
    },
    {
      rank: "Double-Diamond",
      selfDeposit: 10000,
      lowerRank: 2,
      secondLowerRank: 2
    }
  ])
  const [data, setData] = useState({
    totalTeam: 0,
    totalBusiness: 0,
    directTeam: 0,
    strongLegBusiness: 0, 
    package: 0,
    directTeam: 0, 
    directBusiness: 0,
    lowerRank: 0,
    secondLowerRank: 0,
    generation: false,
    royalty: false,
    directDiamonds: 0,
    monthly: false
  })
  const [directRanks, setDirectRanks] = useState([0, 0, 0, 0, 0, 0, 0, 0])
  const [lowerTeams, setLowerTeams] = useState([[0,0,0,0,0], [0,0,0,0,0]]);
  let rankFirst = [0, 0];
  let rankSecond = [0, 0, 0, 0, 0];
  const [generation, setGeneration] = useState([50000, 50000]);
  const [royalty, setRoyalty] = useState([25000, 25000]);
  const [isAvl, setIsAvl] = useState(false);
  const [genLoading, setGenLoading] = useState(false);
  const [isRoyaltyAvl, setIsRoyaltyAvl] = useState(false);
  const [royaltyLoading, setRoyaltyLoading] = useState(false);
  const [monthly, setMonthly] = useState(2);
  const [isMonthlyAvl, setIsMonthlyAvl] = useState(false);
  const [monthlyLoading, setMonthlyLoading] = useState(false);

  function calPer(a, b) {
    let per = parseInt((a*100)/b);
    return per > 100 ? 100 : per;
  }

  useEffect(() => {
    async function getData() {
      let _data = await useAppState.contract.getTeamBusiness(useAppState.walletAddress);
      let _user = await useAppState.contract.userInfo(useAppState.walletAddress);
      let _income = await useAppState.contract.incomeInfo(useAppState.walletAddress);
      let _directTeams = await useAppState.contract.getDirectTeamRanks(useAppState.walletAddress);
      setData({
        totalTeam: _user.totalTeam,
        totalBusiness: useAppState.convert(_data[2]),
        directTeam: Number(_user.directTeam),
        directBusiness: useAppState.convert(_user.directBusiness),
        strongLegBusiness: useAppState.convert(_data[0]),
        package: useAppState.convert(_user.package),
        directTeam: Number(_user.directTeam), 
        generation: _user.generation,
        royalty: _user.royalty,
        monthly: _income.monthly
      })

      let arr = [];
      for(let i=0; i<8; i++) {
        arr.push(Number(_directTeams[i]));
      }
      setDirectRanks(arr);

      let _isAvl = await useAppState.contract.isUpdateGenAvl(useAppState.walletAddress);
      setIsAvl(_isAvl);

      let _isRoyaltyAvl = await useAppState.contract.isUpdateRoyaltyAvl(useAppState.walletAddress);
      setIsRoyaltyAvl(_isRoyaltyAvl);

      // let _isMonthlyAvl = await useAppState.contract.isUpdateMonthlyAvl(useAppState.walletAddress);
      // setIsMonthlyAvl(_isMonthlyAvl);

      let arr1 = [];
      let arr2 = [];
      for(let i=0; i<5; i++) {
        let _check = await useAppState.contract.getFirstAndSecondLowerRank(useAppState.walletAddress, i+3);
        arr1.push(Number(_check[0]));
        arr2.push(Number(_check[1]));
      }

      setLowerTeams([arr1, arr2])
    }
    getData();
  },[useAppState.walletAddress, useAppState.change])

  const updateGen = async () => {
    setGenLoading(true);
    try {
      let tx = await useAppState.contract.updateGeneration(useAppState.walletAddress);
      await tx.wait();
      useAppState.setChange(useAppState.change + 1);
      message.success("Sucessfully Updated");
    } catch (error) {
      message.error(error.reason);
    }
    setGenLoading(false);
  }

  const updateRoyalty = async () => {
    setRoyaltyLoading(true);
    try {
      let tx = await useAppState.contract.updateRoyalty(useAppState.walletAddress);
      await tx.wait();
      useAppState.setChange(useAppState.change + 1);
      message.success("Sucessfully Updated");
    } catch (error) {
      message.error(error.reason);
    }
    setRoyaltyLoading(false);
  }

  const updateMonthly = async () => {
    setMonthlyLoading(true);
    try {
      let tx = await useAppState.contract.updateMonthly(useAppState.walletAddress);
      await tx.wait();
      useAppState.setChange(useAppState.change + 1);
      message.success("Sucessfully Updated");
    } catch (error) {
      message.error(error.reason);
    }
    setMonthlyLoading(false);
  }

  return (
    <>
    <div className='flex'>
      <Sidebar />
      <div className='w-full flex flex-col items-end'>
        <Header />
        <div className='md:p-4 p-1 w-full md:w-4/5 min-h-screen'>
        <img src='../bgelem.png' className='fixed hidden md:block right-0 top-0 -z-10 opacity-90' />
        <img src='../bgmobelem.png' className='fixed w-full left-0 md:top-0 block md:hidden top-0 -z-10 opacity-100' />
        
          <h1 className='font-bold text-2xl mt-3 px-2 md:px-0'>Team <span className='text-violet-500'>Info</span></h1>

          {/* Teams Details */}
          <div className='flex flex-col md:flex-row mt-8 px-4'>
            <div className='flex justify-between w-full md:w-1/2 px-4 bg-gray-800 bg-opacity-30 relative py-4 rounded-lg'>
              <img src='../tiffany-blur.webp' className='absolute top-0 h-full w-full left-0 -z-10 opacity-60 rounded-lg' />
              <div className='flex flex-col'>
                <span className='font-semibold'>Total Team</span>
                <span className='font-semibold text-lg flex items-center'>{Number(data.totalTeam)}</span>
              </div>
              <p className='text-green-500'><Diversity2Icon fontSize='large' /></p>
            </div>
            <div className='flex ml-0 md:ml-4 mt-4 md:mt-0 justify-between w-full md:w-1/2 px-4 bg-gray-800 bg-opacity-30 relative py-4 rounded-lg'>
              <img src='../tiffany-blur.webp' className='absolute top-0 h-full w-full left-0 -z-10 opacity-60 rounded-lg' />
              <div className='flex flex-col'>
                <span className='font-semibold'>Direct Team</span>
                <span className='font-semibold text-lg flex items-center'>{Number(data.directTeam)}</span>
              </div>
              <p className='text-green-500'><CallSplitIcon fontSize='large' className='rotate-180' /></p>
            </div>
          </div>
          <div className='flex flex-col-reverse md:flex-row mt-4 px-4'>
            <div className='flex justify-between mt-4 md:mt-0 w-full md:w-1/2 px-4 bg-gray-800 bg-opacity-30 relative py-4 rounded-lg'>
              <img src='../tiffany-blur.webp' className='absolute top-0 h-full w-full left-0 -z-10 opacity-60 rounded-lg' />
              <div className='flex flex-col'>
                <span className='font-semibold'>Total Business</span>
                <span className='font-semibold text-lg flex items-center'>${data.totalBusiness.toFixed(2)} <img src="/usdt.png" className="h-4 ml-2" /></span>
              </div>
              <p className='text-green-500'><GroupsIcon fontSize='large' /></p>
            </div>
            <div className='flex ml-0 md:ml-4 justify-between w-full md:w-1/2 px-4 bg-gray-800 bg-opacity-30 relative py-4 rounded-lg'>
              <img src='../tiffany-blur.webp' className='absolute top-0 h-full w-full left-0 -z-10 opacity-60 rounded-lg' />
              <div className='flex flex-col'>
                <span className='font-semibold'>Direct Business</span>
                <span className='font-semibold text-lg flex items-center'>${data.directBusiness.toFixed(2)} <img src="/usdt.png" className="h-4 ml-2" /></span>
              </div>
              <p className='text-green-500'><AltRouteIcon fontSize='large' className='rotate-180'  /></p>
            </div>
          </div>

          <div className='mt-12 w-full flex flex-col md:flex-row items-start justify-center md:justify-between md:px-4 px-2'>
            <div className='w-full md:w-1/3'>
              <div className='flex flex-col items-center py-4 relative rounded-xl h-[380px] px-4 w-full'>
                <h1 className='font-bold text-lg'>Direct <span className='text-[#34d178]'>Team Ranks</span></h1>
                <img src='../p-background.png' className='absolute top-0 -z-30 left-0 h-full w-full' />
                <div className='flex w-full mt-4 font-semibold justify-between'>
                  <p>Newbies</p>
                  <p className='text-lime-500'>{directRanks[0]}</p>
                </div>
                <div className='flex w-full mt-4 font-semibold justify-between'>
                  <p>Silver</p>
                  <p className='text-lime-500'>{directRanks[1]}</p>
                </div>
                <div className='flex w-full mt-4 font-semibold justify-between'>
                  <p>Gold</p>
                  <p className='text-lime-500'>{directRanks[2]}</p>
                </div>
                <div className='flex w-full mt-4 font-semibold justify-between'>
                  <p>Platinium</p>
                  <p className='text-lime-500'>{directRanks[3]}</p>
                </div>
                <div className='flex w-full mt-4 font-semibold justify-between'>
                  <p>Emerald</p>
                  <p className='text-lime-500'>{directRanks[4]}</p>
                </div>
                <div className='flex w-full mt-4 font-semibold justify-between'>
                  <p>Ruby</p>
                  <p className='text-lime-500'>{directRanks[5]}</p>
                </div>
                <div className='flex w-full mt-4 font-semibold justify-between'>
                  <p>Diamond</p>
                  <p className='text-lime-500'>{directRanks[6]}</p>
                </div>
                <div className='flex w-full mt-4 font-semibold justify-between'>
                  <p>Double Diamond</p>
                  <p className='text-lime-500'>{directRanks[7]}</p>
                </div>
              </div>

              <div className='w-full py-3 px-2 flex justify-center flex-col items-center mt-5 red_blur rounded-lg'>
                <h1 className='font-bold text-lg'>Generation <span className='text-[#34d178]'>Meter</span> {data.generation ? <span className='text-green-500 text-xs animate-pulse'>~ Active</span> : null}</h1>
                <div className='mt-3 relative flex justify-center items-center flex-col w-full p-2 bg-gray-700 bg-opacity-25 rounded-xl'>
                  <p className='font-semibold font-mono text-sm'>Strong Leg Business</p>
                  <p className='font-mono text-md font-semibold text-lime-500'>${data.strongLegBusiness < generation[0] ? data.strongLegBusiness : generation[0]}/${generation[0]}</p>
                  {data.strongLegBusiness >= generation[0] ? <p className='absolute right-2 top-[25%]'><VerifiedIcon color='success' fontSize='medium' /></p> : null}
                </div>
                <div className='mt-3 mb-3 relative flex justify-center items-center flex-col w-full p-2 bg-gray-700 bg-opacity-25 rounded-xl'>
                  <p className='font-semibold font-mono text-sm'>Other Leg Business</p>
                  <p className='font-mono text-md font-semibold text-lime-500'>${(data.totalBusiness - data.strongLegBusiness) < generation[1] ? (data.totalBusiness - data.strongLegBusiness) : generation[1]}/${generation[1]}</p>
                  {data.totalBusiness - data.strongLegBusiness >= generation[1] ? <p className='absolute right-2 top-[25%]'><VerifiedIcon color='success' fontSize='medium' /></p> : null}
                </div>
                {isAvl ?
                  <Button onClick={updateGen} variant='contained' className='w-full -z-0' color='success'>
                    <p className='font-bold capitalize'>{genLoading ? <TailSpin color='white' height={20} /> : "Update"}</p>
                  </Button>
                : null}
              </div>

              <div className='w-full py-3 px-2 flex justify-center flex-col items-center mt-5 red_blur rounded-lg'>
                <h1 className='font-bold text-lg'>Premium <span className='text-[#34d178]'>Meter</span> {data.royalty ? <span className='text-green-500 text-xs animate-pulse'>~ Active</span> : null}</h1>
                <div className='mt-3 relative flex justify-center items-center flex-col w-full p-2 bg-gray-700 bg-opacity-25 rounded-xl'>
                  <p className='font-semibold font-mono text-sm'>Strong Leg Business</p>
                  <p className='font-mono text-md font-semibold text-lime-500'>${data.strongLegBusiness < royalty[0] ? data.strongLegBusiness : royalty[0]}/${royalty[0]}</p>
                  {data.strongLegBusiness >= generation[0] ? <p className='absolute right-2 top-[25%]'><VerifiedIcon color='success' fontSize='medium' /></p> : null}
                </div>
                <div className='mt-3 mb-3 relative flex justify-center items-center flex-col w-full p-2 bg-gray-700 bg-opacity-25 rounded-xl'>
                  <p className='font-semibold font-mono text-sm'>Other Leg Business</p>
                  <p className='font-mono text-md font-semibold text-lime-500'>${(data.totalBusiness - data.strongLegBusiness) < royalty[1] ? (data.totalBusiness - data.strongLegBusiness) : royalty[1]}/${royalty[1]}</p>
                  {data.totalBusiness - data.strongLegBusiness >= generation[1] ? <p className='absolute right-2 top-[25%]'><VerifiedIcon color='success' fontSize='medium' /></p> : null}
                </div>
                {isRoyaltyAvl ?
                  <Button onClick={updateRoyalty} variant='contained' className='w-full -z-0' color='success'>
                    <p className='font-bold capitalize'>{royaltyLoading ? <TailSpin color='white' height={20} /> : "Update"}</p>
                  </Button>
                : null}
              </div>
            
              {/* <div className='w-full py-3 px-2 flex justify-center flex-col items-center mt-5 red_blur rounded-lg'>
                <h1 className='font-bold text-lg'>Royalty <span className='text-[#34d178]'>Meter</span> {data.monthly ? <span className='text-green-500 text-xs animate-pulse'>~ Active</span> : null}</h1>
                <div className='mt-3 relative flex justify-center items-center flex-col w-full p-2 bg-gray-700 bg-opacity-25 rounded-xl'>
                  <p className='font-semibold font-mono text-sm'>Direct Diamonds</p>
                  <p className='font-mono text-md font-semibold text-lime-500'>{directRanks[6] < monthly ? directRanks[6] : monthly}/{monthly}</p>
                  {directRanks[6] >= monthly ? <p className='absolute right-2 top-[25%]'><VerifiedIcon color='success' fontSize='medium' /></p> : null}
                </div>
                {isMonthlyAvl ?
                  <Button onClick={updateMonthly} variant='contained' className='w-full -z-0' color='success'>
                    <p className='font-bold capitalize'>{monthlyLoading ? <TailSpin color='white' height={20} /> : "Update"}</p>
                  </Button>
                : null}
              </div> */}
            </div>

            <div className='md:mt-0 mt-6 flex flex-col items-center justify-center pink_blur w-full md:w-2/3 ml-0 md:ml-6 px-2 md:px-4 rounded-xl'>
              <h1 className='font-bold text-lg'>Rank <span className='text-[#34d178]'>Meter</span></h1>
              {/* First Two */}
              {rankFirst.map((e, i) => {
                return (
                  <div key={i} className='mt-3 w-full bg-gray-800 bg-opacity-20 rounded-xl p-2'>
                    <div className='flex justify-center items-center'><img src={`../${(rankRequired[i].rank).toLowerCase()}.png`} className='h-12' /> <p className='ml-2 text-teal-500 text-xl font-bold'>{rankRequired[i].rank}</p></div>
                    <div className='flex flex-col md:flex-row w-full justify-between mt-2'>
                      <div className='relative flex justify-center items-center flex-col w-full md:w-1/3 p-2 bg-gray-700 bg-opacity-25 rounded-xl'>
                        <p className='font-semibold font-mono text-sm'>Self Deposit</p>
                        <p className='font-mono text-md font-semibold text-lime-500'>${data.package < rankRequired[i].selfDeposit ? data.package : rankRequired[i].selfDeposit}/${rankRequired[i].selfDeposit}</p>
                        {data.package >= rankRequired[i].selfDeposit ? <p className='absolute right-2 top-[25%]'><VerifiedIcon color='success' fontSize='medium' /></p> : null}
                      </div>
                      <div className='md:ml-2 ml-0 mt-2 md:mt-0 relative flex justify-center items-center flex-col w-full md:w-1/3 p-2 bg-gray-700 bg-opacity-25 rounded-xl'>
                        <p className='font-semibold font-mono text-sm'>Direct Team</p>
                        <p className='font-mono text-md font-semibold text-lime-500'>{data.directTeam < rankRequired[i].directTeam ? data.directTeam : rankRequired[i].directTeam}/{rankRequired[i].directTeam}</p>
                        {data.directTeam >= rankRequired[i].directTeam ? <p className='absolute right-2 top-[25%]'><VerifiedIcon color='success' fontSize='medium' /></p> : null}
                      </div>
                      <div className='md:ml-2 ml-0 mt-2 md:mt-0 relative flex justify-center items-center flex-col w-full md:w-1/3 p-2 bg-gray-700 bg-opacity-25 rounded-xl'>
                        <p className='font-semibold font-mono text-sm'>Direct Business</p>
                        <p className='font-mono text-md font-semibold text-lime-500'>${data.directBusiness < rankRequired[i].directBusiness ? data.directBusiness : rankRequired[i].directBusiness}/${rankRequired[i].directBusiness}</p>
                        {data.directBusiness >= rankRequired[i].directBusiness ? <p className='absolute right-2 top-[25%]'><VerifiedIcon color='success' fontSize='medium' /></p> : null}
                      </div>
                    </div>
                  </div>
              )})}

              {rankSecond.map((e, i) => {
                return (
                  <div key={i} className='mt-3 w-full bg-gray-800 bg-opacity-20 rounded-xl p-2'>
                    <div className='flex justify-center items-center'><img src={`../${(rankRequired[i+2].rank).toLowerCase()}.png`} className='h-12' /> <p className='ml-2 text-teal-500 text-xl font-bold'>{rankRequired[i+2].rank}</p></div>
                    <div className='flex flex-col md:flex-row w-full justify-between mt-2'>
                      <div className='relative flex justify-center items-center flex-col w-full md:w-1/3 p-2 bg-gray-700 bg-opacity-25 rounded-xl'>
                        <p className='font-semibold font-mono text-sm'>Self Deposit</p>
                        <p className='font-mono text-md font-semibold text-lime-500'>${data.package < rankRequired[i+2].selfDeposit ? data.package : rankRequired[i+2].selfDeposit}/${rankRequired[i+2].selfDeposit}</p>
                        {data.package >= rankRequired[i+2].selfDeposit ? <p className='absolute right-2 top-[25%]'><VerifiedIcon color='success' fontSize='medium' /></p> : null}
                      </div>
                      <div className='md:ml-2 ml-0 mt-2 md:mt-0 relative flex justify-center items-center flex-col w-full md:w-1/3 p-2 bg-gray-700 bg-opacity-25 rounded-xl'>
                        <p className='font-semibold font-mono text-sm'>Direct {rankRequired[i+2-1].rank}s</p>
                        <p className='font-mono text-md font-semibold text-lime-500'>{lowerTeams[0][i] < rankRequired[i+2].lowerRank ? lowerTeams[0][i] : rankRequired[i+2].lowerRank}/{rankRequired[i+2].lowerRank}</p>
                        {lowerTeams[0][i] >= rankRequired[i+2].lowerRank ? <p className='absolute right-2 top-[25%]'><VerifiedIcon color='success' fontSize='medium' /></p> : null}
                      </div>
                      <div className='md:ml-2 ml-0 mt-2 md:mt-0 relative flex justify-center items-center flex-col w-full md:w-1/3 p-2 bg-gray-700 bg-opacity-25 rounded-xl'>
                        <p className='font-semibold font-mono text-sm'>Direct {rankRequired[i+2-2].rank}s</p>
                        <p className='font-mono text-md font-semibold text-lime-500'>{lowerTeams[1][i] < rankRequired[i+2].secondLowerRank ? lowerTeams[1][i] : rankRequired[i+2].secondLowerRank}/{rankRequired[i+2].secondLowerRank}</p>
                        {lowerTeams[1][i] >= rankRequired[i+2].secondLowerRank ? <p className='absolute right-2 top-[25%]'><VerifiedIcon color='success' fontSize='medium' /></p> : null}
                      </div>
                    </div>
                  </div>
              )})}

            </div>
          </div>

        </div>
      </div>
    </div>
    </>
  )
}

export default Teams